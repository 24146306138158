body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.MuiDrawer-paper {
  background-color: #1f2937;
  color: white;
}

.MuiListItemText-root {
  font-weight: 500;
}

.MuiAppBar-root {
  background-color: #3f51b5;
}

.MuiPaper-root {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Sidebar active item highlight */
.MuiListItem-root.Mui-selected {
  background-color: #374151 !important;
  color: #60a5fa;
}

.MuiListItem-root:hover {
  background-color: #2d3748;
}

.MuiButton-root {
  text-transform: none;
}

/* Navbar avatar and notification styling */
.navbar-avatar {
  width: 36px;
  height: 36px;
  margin-left: 10px;
  cursor: pointer;
}

.navbar-notifications {
  color: #ffffff;
}

/* Custom content box styling */
.dashboard-content {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

/* Styling for titles */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #374151;
}

/* Styling for balance, transactions, and conversions */
.balance-overview {
  color: #3f51b5;
  font-weight: 600;
  font-size: 1.25rem;
}

.transaction-item {
  padding: 8px 0;
  color: #555;
  border-bottom: 1px solid #f0f0f0;
}

.transaction-item:last-child {
  border-bottom: none;
}
