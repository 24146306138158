.dashboard-container {
    min-height: 100vh;
    background-color: #0a0d10;
    padding: 20px 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Typography */
.dashboard-title {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
}

.section-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #dcdcdc;
}

/* Balance Card */
.balance-card {
    background: linear-gradient(145deg, #1e2025, #2a2d33);
    border-radius: 15px;
    border: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.balance-card:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.card-content {
    padding: 1rem;
}

.turtle-title {
    font-family: 'Courier New', monospace;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    color: #ffffff;
}

.vip-text {
    font-family: 'Courier New', monospace;
    color: #a1a1a1;
}

.card-number {
    font-family: 'Courier New', monospace;
    font-size: 0.9rem;
    color: #a1a1a1;
    margin-top: 10px;
}

/* Buttons */
.connect-wallet-btn {
    background-color: #1e88e5;
    color: white;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 10px;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
}

.connect-wallet-btn:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
}

.action-btn {
    flex: 1;
    background: linear-gradient(45deg, #4ecdc4, #45b7d1);
    color: #ffffff;
    padding: 0.8rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 1rem;
    text-align: center;
    transition: transform .3s ease, box-shadow .3s ease;
}

.action-btn:hover {
    background: #3498db1f !important;
    color: white;
    transform: scale(1.03);
}

/* Staking Section */
.staking-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}

.staking-card {
    flex: 1 1 48%;
    background: #1e2025;
    border: 1px dashed #444;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: border-color 0.3s, transform 0.2s;
    min-width: 300px;
}

.staking-card:hover {
    border-color: #1e88e5;
    transform: scale(1.03);
}

.stake-label {
    display: flex;
    align-items: center;
    color: #dcdcdc;
}

.stake-amount {
    font-weight: 600;
    color: #ffffff;
}

.stake-btn {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #3498db1f;
    color: white;
    background: linear-gradient(45deg, #4ecdc4, #45b7d1);
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: transform .3s ease, box-shadow .3s ease;
}

.stake-btn:hover {
    background: #3498db1f !important;
}

.btn-outline-secondary {
    border: 1px solid #3498db1f;
    color: white;
    background: linear-gradient(45deg, #4ecdc4, #45b7d1);
    transition: transform .3s ease, box-shadow .3s ease;
}

.btn-outline-secondary:hover {
    background: #3498db1f !important;
}

/* Info Cards */
.info-card {
    background: #1e2025;
    border-radius: 12px;
    border: 1px solid #333;
    padding: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.history-label {
    color: #a1a1a1;
    margin-bottom: 0.5rem;
}

.history-amount {
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffffff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .staking-card {
        flex: 1 1 100%;
        margin: 0 auto;
    }

    .staking-cards-wrapper {
        flex-direction: column;
        gap: 15px;
    }

    .connect-wallet-btn {
        width: 100%;
        padding: 0.8rem;
    }
}

button.connect-wallet-btn.right-bts {
    background: transparent !important;
    padding: 0;
}

button.connect-wallet-btn.right-bts.new {
    border: none;
    padding: 0px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease !important;
    background: #3498db1f !important;
    border: 1px solid #3498db1f !important;
    color: #fff;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
}

.wallet-section button.iekbcc0.iekbcc9.ju367v78.ju367v7t.ju367v9i.ju367vn.ju367vei.ju367vf3.ju367v16.ju367v1h.ju367v2g.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
    border: none;
    padding: 0px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease !important;
    background: #3498db1f !important;
    border: 1px solid #3498db1f !important;
    color: #fff;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
}

.admin-settings {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .setting-item {
    margin-bottom: 1rem;
  }
  
  .setting-item label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .setting-item input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .setting-item button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .setting-item button:hover {
    background-color: #0056b3;
  } 