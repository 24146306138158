:root {
  /* Color Palette */
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --background-color: #0e8ba111;
  --text-color: #0D1117;
  --card-background: #ffffff;

  /* Typography */
  --font-family-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .main{
    background-color: #0D1117;
  } */

body {
  font-family: var(--font-family-primary);
  /* background-color:#ffffff; */
  background-color: #0D1117;
  color: var(--text-color);
  line-height: 1.6;
  scroll-behavior: smooth;
}

.bg-bground {
  background: url('../../assets/images/bot-4.jpeg');
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.senseibot-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  background-color: var(--background-color);
}

/* Header Styling */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* background-color: transparent; */
  transition: all 0.3s ease;
}

.header-scrolled {
  background-color: #0D1117;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  max-width: 1440px;
  margin: 0 auto;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--primary-color);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.nav-links a {
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-weight: 500;
}

.nav-links a.active,
.nav-links a:hover {
  color: var(--primary-color);
}

.connect-wallet {
  /* background-color: var(--primary-color);
    color: white; */
  border: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  background: #3498db1f;
  border: 1px solid #3498db1f;
  color: #fff;
}



/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 80px;
  gap: var(--spacing-xl);
  background-color: #0D1117;
}

.hero-content {
  flex: 1;
  padding: 15px;
}

.hero-content h1 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: var(--spacing-md);
  line-height: 1.2;
}

.hero-content p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: var(--spacing-lg);
}

.hero-cta {
  display: flex;
  gap: var(--spacing-md);
}

.hero-cta .primary-btn,
.hero-cta .secondary-btn {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: 30px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.hero-cta .primary-btn {
  background: #3498db1f;
  border: 2px solid #3498db1f;
  color: #fff;
}

.hero-cta .secondary-btn {
  background: transparent;
  border: 2px solid #3498db1f;
  color: #fff;
}

.hero-visual {
  flex: 1;
  padding: 10px;
  width: 100%;
}

.img-div.tex-light img.img{
  width: 100%;
  max-width: 230px;
  height: 100%;
  max-height: 180px;
}

.trading-interface {
  background: url('../../assets/images/bot-1.jpeg');
  background-size: cover;
  min-height: 50vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

/* Features Section */
.features {
  padding: var(--spacing-xl) 0;
  background-color: #f4f6f9;
  /* Soft light background */
}

.features h2 {
  text-align: center;
  margin-bottom: var(--spacing-lg);
  color: #454e5a;
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: -1px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
  max-width: 1362px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.feature-card {
  background: #3498db1f;
  border: 1px solid #3498db1f;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05), 0 5px 15px rgba(0, 0, 0, 0.03);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center,
      rgba(44, 62, 80, 0.05) 0%,
      transparent 70%);
  transform: rotate(-45deg);
  z-index: 1;
  display: none;
}

.feature-card:hover {
  transform: translateY(-15px) scale(1.03);
  box-shadow:
    0 25px 50px rgba(0, 0, 0, 0.1),
    0 10px 20px rgba(0, 0, 0, 0.05);
}

.feature-icon {
  font-size: 3.5rem;
  margin-bottom: var(--spacing-md);
  opacity: 0.85;
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-icon {
  transform: scale(1.1) rotate(5deg);
}

.feature-card h3 {
  margin-bottom: var(--spacing-sm);
  color: #f7faff;
  font-weight: 600;
  font-size: 1.25rem;
}

.feature-card p {
  color: #f4f4f5;
  line-height: 1.6;
  font-size: 0.95rem;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .features-grid {
    grid-template-columns: 1fr;
  }
}

/* Tokenomics Section */

.tokenomics {
  background-color: var(--color-background);
  padding: var(--spacing-xl) 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.section-title {
  text-align: center;
  color: var(--color-text);
  margin-bottom: var(--spacing-lg);
  font-size: 2.5rem;
  font-weight: 700;
}

.tokenomics {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.tokenomics .container {
  width: 100%;
  max-width: 100%;
  padding: 0 var(--spacing-lg);
}

.tokenomics-content {
  width: 100%;
  max-width: 100%;
  background: #3498db1f;
  border: 1px solid #3498db1f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xl);
  border-radius: 0;
  padding: var(--spacing-lg);
  box-shadow: var(--box-shadow);
  color: #fff;
}

.tokenomics-chart {
  flex: 1;
  max-width: 450px;
  position: relative;
}

.tokenomics-details {
  flex: 1;
  max-width: 400px;
}

.tokenomics-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--color-border);
  transition: background-color 0.3s ease;
}

.tokenomics-item:hover {
  background-color: rgba(52, 152, 219, 0.05);
}

.tokenomics-item:last-child {
  border-bottom: none;
}

.tokenomics-item-label {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.tokenomics-item-percentage {
  font-weight: 600;
  color: var(--color-text);
}

/* Responsive Design */
@media (max-width: 768px) {
  .tokenomics-content {
    flex-direction: column;
    text-align: center;
  }

  .tokenomics-chart,
  .tokenomics-details {
    max-width: 100%;
  }

  .tokenomics-item {
    flex-direction: column;
    align-items: center;
  }

  .tokenomics-item-label {
    margin-bottom: var(--spacing-xs);
  }
}

/* Roadmap Section */
.roadmap {
  padding: var(--spacing-xl) 0;
  background-color: white;
}

.roadmap h2 {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.roadmap-timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.roadmap-timeline::before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #3498db1f !important;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.roadmap-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
  position: relative;
}

.roadmap-item:nth-child(odd) {
  flex-direction: row-reverse;
}

.roadmap-marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.roadmap-content {
  background: #3498db1f !important;
  border: 1px solid #3498db1f !important;
  width: 45%;
  background-color: var(--card-background);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
  color: #fff;
}


/* Footer Styling */
.footer {
  background: #3498db1f !important;
  color: white;
  padding: var(--spacing-xl) 0;
  border-top: 1px solid#3498db1f;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-logo {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: var(--spacing-md);
  color: white;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.footer-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: white;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.social-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: white;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .tokenomics-content {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .header-content {
    padding: var(--spacing-sm);
  }

  .nav-links {
    display: none;
    /* Consider implementing mobile menu */
  }

  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-cta {
    justify-content: center;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .roadmap-timeline {
    padding: 0 15px;
    /* Add some padding to prevent content from touching screen edges */
  }

  .roadmap-timeline::before {
    left: 15px;
    /* Adjust the vertical line position */
  }

  .roadmap-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--spacing-md);
    position: relative;
    padding-left: 45px;
    /* Make space for the marker */
  }

  .roadmap-marker {
    position: absolute;
    left: 0;
    top: 0;
  }

  .roadmap-content {
    width: 100%;
    /* Full width for mobile */
    margin-top: var(--spacing-sm);
    margin-left: 0;
    box-sizing: border-box;
    /* Ensure padding doesn't increase width */
  }

  .roadmap-item:nth-child(odd) {
    flex-direction: column;
    /* Ensure consistent layout for all items */
  }

  /* Mobile adjustments */
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-cta .primary-btn,
  .hero-cta .secondary-btn {
    padding: var(--spacing-xs) var(--spacing-md);
  }

  .feature-card {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .nav-links {
    display: block;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-cta .primary-btn,
  .hero-cta .secondary-btn {
    font-size: 0.9rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
}

/* Global styles */
.about-section {
  padding: 60px 0 0 0;
  background-color: #0D1117;
  color: #fff;
}

.about-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-section .heading-p {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
  /* text-align: center; */
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: -1px;
}

.about-section .about-p {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  /* White text color */
  margin-bottom: 15px;
}

.about-section .about-p-1 {
  font-size: 16px;
  line-height: 1.8;
  color: #fff;
  /* White text color */
  margin-bottom: 20px;
}

/* Styling for image */
.about-section img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}

/* Left and Right about div styles */
.left-about,
.right-about {
  padding: 20px;
}

.about-section img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease;
}

.green-img{
  max-width: 21% !important;
}

.about-section img:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
}
.col-lg-7.content-center {
  align-items: center;
  display: flex;
}

.order-class .col-lg-5 {
  order: 1;
}

.order-class .col-lg-7 {
  order: 2;
}

/* Responsive Grid Layout */
@media (max-width: 991px) {
  .container {
    flex-direction: column;
    text-align: center;
  }

  .left-about,
  .right-about {
    padding: 15px;
  }

  .about-section .heading-p {
    font-size: 2.5rem;
  }

  .about-section .about-p {
    font-size: 1.3rem;
  }
  .order-class .col-lg-5 {
    order: 2;
  }
  
  .order-class .col-lg-7 {
    order: 1;
  }
}

@media (max-width: 767px) {
  .about-section .heading-p {
    font-size: 2rem;
  }

  .about-section .about-p {
    font-size: 1.2rem;
  }

  .about-section .about-p-1 {
    font-size: 0.9rem;
  }
}

/* Subtle Fade-In Animations */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}